import React from 'react';
import BankPage from 'pages/bank';
import MarketPlacePage from 'pages/marketplace';
import NftPage from 'pages/nft';
import ProfilePage from 'pages/profile';
import FarmsPage from '../pages/farms';
import Fix from "../pages/fix";
import MintAlphaPage from '../pages/mint-elves';
import StakingPage from '../pages/staking';

export interface IRoute {
  path: string;
  element: React.FunctionComponent;
  isRoot?: boolean;
  isPrivate?: boolean;
  isDev?: boolean;
  maintenance?: number;
}

const ApplicationRoutes: Array<IRoute> = [
  {
    path: '/gallery/:category',
    element: MarketPlacePage,
    isRoot: true,
  },
  //{
  //  path: '/market/land/map',
  //  element: MarketPlacePage,
  //  isDev: true,
  //},
  //{
  //  path: '/bridge',
  //  element: Bridge,
  //},
  {
    path: '/fix',
    element: Fix,
  },
  {
    path: '/gallery/:category/:contract/:tokenId',
    element: NftPage,
  },
  //{
  //  path: '/gallery/dashboard',
  //  element: DashboardPage,
  //  isDev: true,
  //},
  {
    path: '/profile',
    element: ProfilePage,
    isRoot: true,
    isPrivate: true,
  },
  {
    path: '/users/:wallet',
    element: ProfilePage,
  },
  {
    path: '/staking',
    element: StakingPage,
    isPrivate: true,
  },
  {
    path: '/bank',
    element: BankPage,
  },
  {
    path: '/mint',
    element: MintAlphaPage
  },
  //{
  //  path: '/troubleshooting',
  //  element: TroubleshootingPage,
  //  isPrivate: true,
  //},
  {
    path: '/farms',
    element: FarmsPage,
  },
];
export default ApplicationRoutes;
