import { characterRaces, ICharacterRace } from "constants/filters/Character";
import { IRegionFilter, regionItems } from "constants/filters/Lands";
import { BigNumber, utils } from "ethers";
import Utils from "libs/Utils";
import { IAsset, NFTType } from "models/@types";
import moment from "moment";
import store from "store";
import { Address, Chain } from "wagmi";
import { GetCategoryInfoFromToken } from "../constants/Addresses";
import {
  RawAttributesChestsClaimed,
  RawAttributesMap,
  RawAttributesProfessionsMap,
  RawAttributesStakingMap
} from "../constants/attributes/functions";
import { ChainList } from "../constants/wagmi/chains";
import { SaleType } from "../store/market/types";
import { Sale } from "./Sale";

export type RawAttribute = {
  trait_type: string;
  value: any;
};

export type NFTAttributes = {
  [index: string]: any;
};

export interface NFTMetaDataAttr {
  trait_type: string;
  value: string;
}

export interface IBuySale {
  amount: string;
  time: string;
  wallet: string;
}

export type INFTDetailsAttr = Record<string, any>;
export type NFTAction =
  | "SALE"
  | "CANCEL_SALE"
  | "HAS_BIDDER"
  | "BUY"
  | "BID"
  | "BURNED"
  | "OFFER"
  | "CANCEL_OFFER"
  | "LOGIN_TO_BUY"
  | "FINALIZE"
  | "FINALIZE_AND_CANCEL"
  | "BIDDER";

export interface IBuyerSeller{
  _id: string;
  name: string;
  wallet: string;
}

export interface IBidToken{
  address: string;
  decimals: number;
  name: string;
  symbol: string;
}

export interface INFTDetailsOwner{
  amount: number;
  wallet: string;
  name: string;
}

export interface INFDetailRarity{
  rank: number;
  categoryRank: number;
}

export interface INFTDetailSale{
  bidToken: IBidToken;
  saleId: number;
  isBundle: boolean;
  minPrice: string;
  price: string;
  status: string;
  startTime: string;
  endTime: string;
  type: string;
  buyer: IBuyerSeller;
  seller: IBuyerSeller;
}

export interface INFTProfessions{
  tailoring: number;
  alchemy: number;
  architecture: number;
  carpentry: number;
  cooking: number;
  crystalExtraction: number;
  farming: number;
  fishing: number;
  gemCutting: number;
  herbalism: number;
  mining: number;
  woodcutting: number;
}

export type INFTTraining = {
  _address: string;
  tokenId: BigNumber;
  level: BigNumber;
  treeId: BigNumber;
  skillId: BigNumber;
  startedAt: BigNumber;
  completeAt: BigNumber;
};

export interface NFTTraining{
  skillId: number;
  treeId: number;
  level: number;
  startedAt: Date;
  completeAt: Date;
}

export interface INFTStaking{
  unlocked: number;
  staked: number;
}

export interface NFTStaking{
  unlocked: boolean;
  staked: boolean;
}

export interface NFTMetaData {
  name: string;
  image: string;
  attributes: NFTMetaDataAttr[];
  professions?: INFTProfessions;
  staking?: INFTStaking;
}

export type NFTProfession =
  | 'alchemy'
  | 'architecture'
  | 'carpentry'
  | 'cooking'
  | 'crystalExtraction'
  | 'farming'
  | 'fishing'
  | 'gemCutting'
  | 'herbalism'
  | 'mining'
  | 'tailoring'
  | 'woodcutting';

export type PrettyNFTProfession =
  | 'Alchemy'
  | 'Architecture'
  | 'Carpentry'
  | 'Cooking'
  | 'Crystal Extraction'
  | 'Farming'
  | 'Fishing'
  | 'Gem Cutting'
  | 'Herbalism'
  | 'Mining'
  | 'Tailoring'
  | 'Woodcutting';

export type NFTProfessions = {
  [key in NFTProfession]: number;
};

export const prettyNFTProfessions: { [key in NFTProfession]: PrettyNFTProfession } = {
  alchemy: 'Alchemy',
  architecture: 'Architecture',
  carpentry: 'Carpentry',
  cooking: 'Cooking',
  crystalExtraction: 'Crystal Extraction',
  farming: 'Farming',
  fishing: 'Fishing',
  gemCutting: 'Gem Cutting',
  herbalism: 'Herbalism',
  mining: 'Mining',
  tailoring: 'Tailoring',
  woodcutting: 'Woodcutting',
};

export const GetProfessionId = (prof: NFTProfession): number | null => {
  const k = Object.keys(prettyNFTProfessions) as NFTProfession[];
  const cleaned = k.map((p, i) => (p === prof ? i : null));
  const id = cleaned.find(i => i !== null);
  if (id !== null && id !== undefined) {
    return id;
  }
  return null;
};

export const GetPrettyProfessionId = (prof: PrettyNFTProfession): number | null => {
  const k = Object.values(prettyNFTProfessions) as PrettyNFTProfession[];
  const cleaned = k.map((p, i) => (p === prof ? i : null));
  const id = cleaned.find(i => i !== null);
  if (id !== null && id !== undefined) {
    return id;
  }
  return null;
};

export const GetProfessionFromId = (id: number): NFTProfession | null => {
  const k = Object.keys(prettyNFTProfessions) as NFTProfession[];
  const cleaned = k.map((p, i) => (i === id ? p : null));
  const profession = cleaned.find(i => i !== null);
  if (profession) {
    return profession;
  }
  return null;
};

export const GetPrettyProfessionFromId = (id: number): PrettyNFTProfession | null => {
  const p = GetProfessionFromId(id);
  if (!p) {
    return null;
  }
  return prettyNFTProfessions[p];
};

export interface INFTSale {
  bidToken: IBidToken;
  saleId: number;
  contractAddress: string;
  duration: number;
  extensionDuration: number;
  isBundle: boolean;
  minPrice: string;
  price: string;
  sortPrice: number;
  status: string;
  startTime: string;
  endTime: string;
  type: string;
  buy: IBuySale;
  buyer: IBuyerSeller;
  seller: IBuyerSeller;
  tokenIds: number[];
}

export interface NFTInterface {
  name: string;
  customName?: string;
  description: string;
  image?: string;
  attributes: RawAttribute[];
  animation_url?: string;
  owner: string;
  sale: INFTSale;
  rarity: INFDetailRarity | null;
  type: string;
  _id: string;
  tokenId: number;
  offers: INFTSale[];
  metadata: NFTMetaData;
  professions?: INFTProfessions;
  burned: boolean;
  staking?: INFTStaking;
  training?: INFTTraining;
  balance?: number;

  chain?: number;
}

export class NFT {
  name: string;
  customName?: string;
  description: string;
  image?: string;
  attributes: NFTAttributes;
  animation_url?: string;
  category: string;
  contract: string;
  subCategory: string;
  owner: string;
  sale?: Sale;
  type: string;
  id: string;
  tokenId: number;
  offers: INFTDetailSale[];
  metadata: NFTMetaData;
  professions?: INFTProfessions;
  staking?: NFTStaking;
  training?: NFTTraining;
  burned: boolean;
  userOffer?: INFTDetailSale;
  balance?: number;
  chestsClaimed: number;
  chain?: Chain;
  constructor(props: NFTInterface, token: string, account?: string, contract?: Address, chain?: Chain) {
    const tokenId = props.tokenId || Number(props.name.split('#')[1]) || 0;
    const [category, subCategory, catContract] = GetCategoryInfoFromToken(token, props.chain || chain?.id || 43114);
    this.chain = props.chain ? ChainList.find(c => c.id === props.chain) : chain as any;
    this.category = category;
    this.type = props.type || 'ERC721';
    this.contract = catContract;
    this.subCategory = subCategory;
    this.description = props.description;
    this.animation_url = props.animation_url;
    this.image = props.image;
    if (this.image?.includes('elves-tickets')) {
      this.image = this.image?.replace("images", "r2") + ".mp4"
    }
    this.owner = props.owner ? utils.getAddress(props.owner) : account ? utils.getAddress(account) : '';
    this.name = props.name;
    this.id = `${this.contract}-${tokenId}`;
    this.tokenId = Number(tokenId);
    this.attributes = RawAttributesMap(subCategory, props.attributes) || null;
    this.chestsClaimed = RawAttributesChestsClaimed(props.attributes);
    this.offers = props.offers || [];
    this.metadata = {
      name: props.metadata?.name || '',
      attributes: props.metadata?.attributes || [],
      image: props.metadata?.image || '',
    } || {
      attributes: [],
      image: '',
      name: '',
    };
    this.burned = props.burned || props.owner === '0x0000000000000000000000000000000000000001';
    this.professions = RawAttributesProfessionsMap(props.attributes);
    this.staking = RawAttributesStakingMap(props.attributes);
    //this.training = props.training;
    if (this.type == 'ERC1155') {
      this.balance = props.balance;
    }
  }

  setTrainingStatus = (ts: INFTTraining) => {
    this.training = {
      skillId: ts.skillId.toNumber(),
      treeId: ts.treeId.toNumber(),
      level: ts.level.toNumber(),
      startedAt: new Date(ts.startedAt.toNumber() * 1000),
      completeAt: new Date(ts.completeAt.toNumber() * 1000),
    };
  };

  get baseAttributes(): NFTAttributes {
    return Object.entries(this.attributes).reduce((a, [k, v]) => {
      if (isNaN(Number(v)) && !['true', 'false'].includes(String(v).toLowerCase())) {
        a[k] = v;
      }
      return a;
    }, {} as NFTAttributes);
  }
  get rarity(): INFDetailRarity | null {
    if (this.subCategory === 'Elves') {
      return null;
    }
    return Utils.getWizardRarity(Number(this.tokenId));
  }

  get rarityBonus(): number {
    const rarest = Utils.getRarestAttribute(this.subCategory, this.baseAttributes);
    return rarest;
  }

  get asset(): IAsset {
    return { src: this.animation_url || this.image || '', alt: this.metadata.name };
  }

  get race(): ICharacterRace | undefined {
    return characterRaces.find(item => item.type === this.subCategory);
  }

  get priceStr(): string {
    if (this.sale) {
      return this.sale.bidAmount.div(utils.parseEther('1.0')).toNumber().toLocaleString();
    }
    return '0.00';
  }

  get numeralPrice(): number {
    return parseFloat(this.priceStr.replace(/\D/g, ''));
  }

  get startDate(): string {
    if (this.sale && this.sale.startTime) {
      return moment(this.sale.startTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get region(): IRegionFilter | undefined {
    switch (Number(this.attributes?.region)) {
      case 2: {
        return regionItems[0];
      }
      case 3: {
        return regionItems[1];
      }
      case 4: {
        return regionItems[2];
      }
      case 5: {
        return regionItems[3];
      }
      default: {
        return undefined;
      }
    }
  }

  get discount(): string | undefined {
    if (this.attributes) {
      return this.attributes.discount;
    }
  }
  get nftType(): NFTType | null {
    const { categories } = store.getState().app;
    const current = categories.find(item => item.address.toLowerCase() === this.contract.toLowerCase());
    if (current) {
      return current.type;
    }
    return null;
  }
  get coordinate(): string {
    return `${this.x}, ${this.y}`;
  }

  get x(): number {
    return this.attributes?.latitude > 99 ? -(this.attributes?.latitude - 100) : this.attributes?.latitude;
  }

  get y(): number {
    return this.attributes?.longitude > 99 ? -(this.attributes?.longitude - 100) : this.attributes?.longitude;
  }

  get usdtPrice(): string {
    const { magickPrice } = store.getState().constants;
    const price = this.numeralPrice;
    return Utils.addComma(price * magickPrice);
  }
  actionText(account?: string): string {
    switch (this.actionType(account)) {
      case 'BUY':
        return this.sale?.type === SaleType.AUCTION ? 'Place a Bid' : 'Buy Now';
      case 'OFFER':
        return 'Make an Offer';
      case 'SALE':
        return 'Sell Now';
      case 'CANCEL_OFFER':
        return 'Cancel Offer';
      case 'CANCEL_SALE':
        return 'Cancel Sale';
      case 'LOGIN_TO_BUY':
        return 'Login to Buy';
      case 'BIDDER':
        return 'You are the bidder';
      case 'FINALIZE':
        return 'Finalize';
      case 'HAS_BIDDER':
        return `Highest bid is $${this.numeralPrice}`;
      default:
        return 'Login to Buy';
    }
  }
  get isForSale(): boolean {
    return this.sale !== null;
  }
  actionType(account?: string): NFTAction | 'OWNER' | 'ENDED' {
    if (!account) {
      return 'LOGIN_TO_BUY';
    }

    if (!this.sale && this.owner !== account) {
      return 'OFFER';
    }

    if (this.sale) {
      if (this.sale.bidder === account) {
        return 'BIDDER';
      }
      if (this.sale.seller === account) {
        if (this.sale.type === SaleType.FIXED || (this.sale.type === SaleType.AUCTION && !this.sale.bidder)) {
          return 'CANCEL_SALE';
        } else {
          return 'HAS_BIDDER';
        }
      }
      if (this.sale.endTime < Date.now()) {
        return 'ENDED';
      }
      if (this.sale.type === SaleType.AUCTION) {
        return 'BID';
      }
    }

    if (this.owner === account) {
      return 'SALE';
    }
    return 'BUY';
  }

  get endDate(): string {
    if (this.sale) {
      return moment(this.sale.endTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get coordinates(): string {
    return `${this.x}, ${this.y}`;
  }

  get totalProfessionPoints(): number {
    if (!this.professions) {
      return 0;
    }
    return Object.values(this.professions).reduce((p, v) => v + p, 0);
  }
}
