import { BigNumber, constants, Signer } from 'ethers';
import store from 'store';
import {
  CreateHRC20Contract,
  CreateHRC721Contract,
  CreateProfessionStakingContract,
  CreateStakingRewardsClaimContract,
} from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';
import { Addresses } from '../constants/Addresses';
import Chains from "../constants/wagmi/chains";
import { GetPrettyProfessionFromId, NFT } from '../models/NFT';
import {setAppLoading} from "../store/app/reducer";

export const wizardApproval = async (signer: Signer) => {
  const contract = CreateProfessionStakingContract(signer);
  const wizards = CreateHRC721Contract(Addresses[Chains.avalanche.id].NFTs.Wizards, signer);

  if (!contract || !wizards) {
    return new Error('Contract initialization issue');
  }
  const address = contract.signer.getAddress();
  const approvedForAll = await wizards.isApprovedForAll(address, contract.address);
  if (!approvedForAll) {
    const txApproval = await wizards.setApprovalForAll(contract.address, true);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved Wizards`,
      severity: 'success',
    });
  }
};

export const magicApproval = async (signer: Signer, spender?: string) => {
  const magick = CreateHRC20Contract(signer);
  const contract = CreateProfessionStakingContract(signer);
  const address = magick.signer.getAddress();
  const approved = await magick.allowance(address, spender || contract.address);
  if (approved.lt(constants.MaxUint256.div(2))) {
    const txApproval = await magick.approve(spender || contract.address, constants.MaxUint256);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved MAGICK`,
      severity: 'success',
    });
  }
};

export const UnlockStaking = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const magic = CreateHRC20Contract(signer);
  const contract = CreateProfessionStakingContract(signer);

  if (!contract || !magic) {
    return false;
  }

  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.enableStaking(nft.tokenId);
    await tx.wait(1);
    showSnackbar({
      message: `Unlocked staking for Cosmic Wizard #${nft.tokenId}`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchUnlockStaking = async (tokenIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    await wizardApproval(signer);
    const tx = await contract.batchEnableStaking(tokenIds);
    await tx.wait(1);
    showSnackbar({
      message: `Unlocked staking for ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.reason ?? err?.error?.message ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const stake = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  const wizards = CreateHRC721Contract(Addresses[Chains.avalanche.id].NFTs.Wizards, signer);
  if (!contract || !wizards) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await wizardApproval(signer);
    const tx = await contract.stake(nft.tokenId);
    await tx.wait(1);
    showSnackbar({
      message: `Staked Cosmic Wizard #${nft.tokenId}`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchStake = async (tokenIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    await wizardApproval(signer);
    const tx = await contract.batchStake(tokenIds);
    await tx.wait(1);
    showSnackbar({
      message: `Staked ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const unstake = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.unstake(nft.tokenId);
    await tx.wait(1);
    showSnackbar({
      message: `Unstaked Cosmic Wizard #${nft.tokenId}`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchUnstake = async (tokenIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.batchUnstake(tokenIds);
    await tx.wait(1);
    showSnackbar({
      message: `Unstaked ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const claim = async (ids: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateStakingRewardsClaimContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.batchClaim(ids);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Claimed all rewards`,
      severity: 'success',
    });
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const StartTraining = async (nft: NFT, skillId: number, signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.startTraining(nft.tokenId, skillId);
    await tx.wait(1);
    showSnackbar({
      message: `Started training ${GetPrettyProfessionFromId(skillId)} for Cosmic Wizard #${nft.tokenId}`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchStartTraining = async (tokenIds: number[], skillIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.batchStartTraining(tokenIds, skillIds);
    await tx.wait(1);
    showSnackbar({
      message: `Started training for ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const FinishTraining = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.finishTraining(nft.tokenId);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchFinishTraining = async (tokenIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);

  if (!contract) {
    return false;
  }

  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.batchFinishTraining(tokenIds);
    await tx.wait(1);
    showSnackbar({
      message: `Finished training for ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const CancelTraining = async (nft: NFT, signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.cancelTraining(nft.tokenId);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const BatchCancelTraining = async (tokenIds: number[], signer: Signer): Promise<boolean> => {
  const contract = CreateProfessionStakingContract(signer);
  if (!contract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    await magicApproval(signer);
    const tx = await contract.batchCancelTraining(tokenIds);
    await tx.wait(1);
    showSnackbar({
      message: `Canceled training for ${tokenIds.length} Cosmic Wizards`,
      severity: 'success',
    });
    store.dispatch(setAppLoading(false));
    return !!tx;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export type TrainingStatus = {
  address: string;
  tokenId: BigNumber;
  level: BigNumber;
  treeId: BigNumber;
  skillId: BigNumber;
  startedAt: BigNumber;
  completeAt: BigNumber;
};
