import { FC } from 'react';
import { BigNumber, constants } from "ethers";
import GetLogger from '../../../components/global/Logger';
import { ABIs, Addresses} from "../../../constants/Addresses";
import Chains from "../../../constants/wagmi/chains";
import { useWagmi } from "../../../hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectWalletTokenBalances } from '../hooks';
import { setWalletTokenBalanceOf } from "../reducer";
import { useContractRead, useContractReads } from "wagmi";

const WalletTokenUpdater: FC = (): null => {
  const { address } = useWagmi();
  const log = GetLogger('Wallet Updater');
  const dispatch = useAppDispatch();
  const balances = useAppSelector(selectWalletTokenBalances);

  useContractReads({
    contracts: Object.entries(Addresses[Chains.avalanche.id].Tokens).map(([name, _address]) => ({
      address:  _address,
      abi: ABIs.Tokens.MAGICK,
      functionName: 'balanceOf',
      args: [address],
      chainId: Chains.avalanche.id
    })),
    cacheTime: 60_000,
    enabled: address !== constants.AddressZero,
    onSuccess: (data) => {
      const now = Date.now();
      Object.entries(Addresses[Chains.avalanche.id].Tokens).map(([token], i) => {
        const payload = { token, balance: { timestamp: now, balance: data[i] as unknown as BigNumber } };
        dispatch(setWalletTokenBalanceOf(payload));
      })
    }
  })

  useContractReads({
    contracts: Object.entries(Addresses[Chains.avalanche.id].Tokens).map(([name, _address]) => ({
      address: _address,
      abi: ABIs.Tokens.MAGICK,
      functionName: 'totalSupply',
      chainId: Chains.avalanche.id,
    })),
    cacheTime: 60_000,
    onSuccess: (data) => {
      const now = Date.now();
      Object.entries(Addresses[Chains.avalanche.id].Tokens).map(([token], i) => {
        const payload = { token, balance: { timestamp: now, total: data[i] } };
        dispatch(setWalletTokenBalanceOf(payload));
      })
    }
  })

  useContractRead({
    address: Addresses[Chains.avalanche.id].Tokens.MAGICK,
    abi: ABIs.Tokens.lMAGIC,
    functionName: 'balanceOf',
    args: [Addresses[Chains.avalanche.id].Tokens.xMAGIC || '0x'],
    cacheTime: 60_000,
    chainId: Chains.avalanche.id,
    enabled: address !== constants.AddressZero,
    onSuccess: (data) => {
      const payload = { token: 'xMAGIC', balance: { timestamp: Date.now(), locked: data } };
      dispatch(setWalletTokenBalanceOf(payload));
    },
    onError: (err) => {
      log.error("Multicall::xMAGIC Locked::", err)
    }
  })

  const lMagicContract = {
    address: Addresses[Chains.avalanche.id].Tokens.lMAGIC,
    abi: ABIs.Tokens.lMAGIC,
    chainId: Chains.avalanche.id
  }
  useContractReads({
    contracts: [
      { ...lMagicContract, functionName: 'pendingOf', args: [address] },
      { ...lMagicContract, functionName: 'totalOf', args: [address] },
    ],
    cacheTime: 60_000,
    enabled: address !== constants.AddressZero,
    onSuccess: (data) => {
      const payload = { token: 'lMAGIC', balance: { timestamp: Date.now(), unlocked: data[0], locked: data[1] } };
      dispatch(setWalletTokenBalanceOf(payload));
    },
    onError: (err) => {
      log.error("Multicall::lMAGIC::", err)
    }
  })


  return null;
};

export default WalletTokenUpdater;
