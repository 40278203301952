import { ethers, Contract, Signer } from 'ethers';
import { Contract as MCContract } from 'ethers-multicall';

import { ABIs, Addresses } from "constants/Addresses";
import Chains from "../constants/wagmi/chains";

export const GetSignedContract = (contract: MCContract, signer: Signer) =>
  new Contract(contract.address, contract.abi, signer);

export const CreateMagickContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].Tokens.MAGICK, ABIs.Tokens.MAGICK, signer);

export const CreateHRC20Contract = (signer: Signer, address?: string): ethers.Contract =>
  new Contract(address || Addresses[Chains.avalanche.id].Tokens.MAGICK, ABIs.Tokens.MAGICK, signer);

export const CreateHRC1155Contract = (address: string, signer: Signer): ethers.Contract =>
  new Contract(address, ABIs.Items1155.Badges, signer);

export const CreateHRC721Contract = (address: string, signer: Signer): ethers.Contract =>
  new Contract(address, ABIs.NFTs.Wizards, signer);

export const CreateProfessionStakingContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].NPCs.ProfessionStaking || "", ABIs.NPCs.ProfessionStaking, signer);

export const CreateStakingRewardsClaimContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].NPCs.StakingRewardsClaim || "", ABIs.NPCs.StakingRewardsClaim, signer);


export const CreateFarmContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].NPCs.Farm || "", ABIs.NPCs.Farm, signer);

export const CreateLPTokenContract = (address: string, signer: Signer): ethers.Contract =>
  new Contract(address, ABIs.LpTokens.MAGIC_AVAX, signer);

export const CreateAssetCustomizationContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].NPCs.AssetCustomization || "", ABIs.NPCs.AssetCustomization, signer);

export const CreateXMagicContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].Tokens.xMAGIC || "", ABIs.Tokens.xMAGIC, signer);

export const CreateLMagicContract = (signer: Signer): ethers.Contract =>
  new Contract(Addresses[Chains.avalanche.id].Tokens.lMAGIC || "", ABIs.Tokens.lMAGIC, signer);

/// ethers-multicall
export const GetFarmContract = (): MCContract => new MCContract(Addresses[Chains.avalanche.id].NPCs.Farm || '', ABIs.NPCs.Farm as any);
export const GetLpTokenContract = (address: string): MCContract => new MCContract(address, ABIs.LpTokens.MAGIC_AVAX as any);
export const GetCosmicElvesMinterContract = (): MCContract =>
  new MCContract(Addresses[Chains.avalanche.id].NPCs.ElvesMinter || '', ABIs.NPCs.ElvesMinter as any);
