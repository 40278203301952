import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { IMarketPlacePageParams } from "routes/params";
import useTitle from "hooks/useTitle";
import { NFTCategory } from "models/@types";
import { ItemType } from "models/Filters";
import { NFT, NFTProfession } from "models/NFT";
import PageContainer from "components/PageContainer";
import AlertBox from "components/common/alert-box";
import Categories from "components/common/categories";
import Div from "components/global/div";
import GetLogger from "../../components/global/Logger";
import { selectGlobalNFTs, selectGlobalItems } from "../../store/global/hooks";
import { useAppSelector } from "../../store/hooks";
import { selectMarketNfts } from "../../store/market/hooks";
import {
  selectWalletItems1155,
  selectWalletItems721,
} from "../../store/wallet/hooks";
import { NFTState } from "../../store/wallet/types";
import NFTListContainer, { classes } from "./_styles";
import FiltersSection from "./components/filters";
import NFTList from "./components/nft-list";
import { setCategory } from "../../store/marketplace/reducer";

const MarketPlacePage: React.FunctionComponent = () => {
  const location = useLocation();

  const log = GetLogger("Gallery Page");
  const { category: catParam } = useParams<IMarketPlacePageParams>();
  const navigate = useNavigate();
  useTitle({ title: "Gallery" });
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"), {
    noSsr: true,
  });
  const dispatch = useDispatch();
  const { category, sort } = useAppSelector((state) => state.marketplace);
  const onChangeCategory = (cat: NFTCategory) => {
    navigate(`/gallery/${cat}`);
  };
  const marketNfts = useAppSelector(selectMarketNfts);
  const allNfts = useAppSelector(selectGlobalNFTs);
  const globalItems = useAppSelector(selectGlobalItems);
  const walletItems1155 = useAppSelector(selectWalletItems1155);

  const [displayNFTs, setDisplayNFTs] = useState<NFTState>();

  const filters = useAppSelector((state) => state.marketplace);

  const stringToCapitalise = (type: string) => {
    type = type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    return type;
  };

  const sortNFTs = (nftData: NFTState) => {
    if (!nftData) {
      return {};
    }
    const sortMethod = sort.slice(1, sort.length);
    const sortOrder = sort.slice(0, 1);
    const sortedNFTs: NFTState = {};

    if (sortMethod === "rarity") {
      Object.entries(nftData)
        .sort(
          ([, a]: [string, NFT], [, b]: [string, NFT]) =>
            ((sortOrder === "+" ? b.rarity?.rank : a.rarity?.rank) || 0) -
            ((sortOrder === "+" ? a.rarity?.rank : b.rarity?.rank) || 0)
        )
        .forEach(([key, _nft]) => {
          sortedNFTs[key] = _nft;
        });
    } else if (sortMethod === "tokenId") {
      Object.entries(nftData)
        .sort(
          ([, a]: [string, NFT], [, b]: [string, NFT]) =>
            (sortOrder === "+" ? a.tokenId : b.tokenId) -
            (sortOrder === "+" ? b.tokenId : a.tokenId)
        )
        .forEach(([key, _nft]) => {
          sortedNFTs[key] = _nft;
        });
    } else if (sortMethod === "sale.price") {
      Object.entries(nftData)
        .sort(
          ([, a]: [string, NFT], [, b]: [string, NFT]) =>
            (sortOrder === "+" ? a.numeralPrice : b.numeralPrice) -
            (sortOrder === "+" ? b.numeralPrice : a.numeralPrice)
        )
        .forEach(([key, _nft]) => {
          sortedNFTs[key] = _nft;
        });
    } else if (sortMethod === "createdAt") {
      Object.entries(nftData)
        .sort(
          ([, a]: [string, NFT], [, b]: [string, NFT]) =>
            (b.sale?.startTime || 0) - (a.sale?.startTime || 0)
        )
        .forEach(([key, _nft]) => {
          sortedNFTs[key] = _nft;
        });
    }

    return sortedNFTs;
  };

  const filterNFTs = (nftData: NFTState) => {
    const newNFTData: NFTState = {};

    if (nftData) {
      Object.entries(nftData).map(([key, item]) => {
        switch (category) {
          case "character":
            if (
              filters.characterId === "" ||
              item.tokenId.toString() === filters.characterId
            ) {
              if (
                filters.races.length === 0 ||
                (item.race && filters.races.includes(item.race.type))
              ) {
                if (
                  filters.blockchains.length === 0 ||
                  (item.chain?.name &&
                    filters.blockchains.includes(item.chain?.name as any))
                ) {
                  if (
                    filters.professions.length === 0 ||
                    (item.professions &&
                      item.professions[filters.professions[0]] > 0)
                  ) {
                    newNFTData[key] = item;
                  }
                }
              }
            }
            break;
          case "land":
            if (
              filters.characterId === "" ||
              item.tokenId.toString() === filters.characterId
            ) {
              if (
                filters.regions.length === 0 ||
                (item.region && filters.regions.includes(item.region.text))
              ) {
                newNFTData[key] = item;
              }
            }
            break;
          default:
            if (
              filters.types.length === 0 ||
              (item.subCategory &&
                (filters.types.includes(
                  stringToCapitalise(item.subCategory + "s") as ItemType
                ) ||
                  filters.types.includes(
                    stringToCapitalise(item.subCategory) as ItemType
                  )))
            ) {
              newNFTData[key] = item;
            }
            break;
        }
      });
    }

    return newNFTData;
  };

  const checkNFTs = (nftData: NFTState) => {
    return filterNFTs(sortNFTs(nftData));
  };

  const setNFTs = () => {
    if (allNfts || marketNfts || walletItems1155 || globalItems) {
      if (category === "character") {
        const nfts = {
          ...allNfts.Wizards,
          ...allNfts.Elves,
        };
        if (Object.keys(nfts).length > 0) {
          setDisplayNFTs(checkNFTs(nfts));
        }
      } else if (category === "land") {
        try {
          if (Object.keys(allNfts.CosmicIslandLand || {}).length > 0) {
            setDisplayNFTs(checkNFTs(allNfts.CosmicIslandLand));
          }
        } catch (e) {
          //
        }
      } else if (category === "collectable") {
        const nfts = {
          ...allNfts.ElvesTicket,
          ...allNfts.FramedWizards,
        };
        if (Object.keys(nfts).length > 0) {
          setDisplayNFTs(checkNFTs(nfts));
        }
      } else {
        const nfts = Object.values(walletItems1155 || {}).reduce((o, a) => {
          o = { ...o, ...a };
          return o;
        }, {} as NFTState);
        if (Object.keys(nfts).length > 0) {
          setDisplayNFTs(checkNFTs(nfts));
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (catParam && catParam !== category) {
      dispatch(setCategory(catParam));
    }
  }, [catParam]);

  useEffect(() => {
    if (allNfts || marketNfts) {
      setNFTs();
    }
  }, [allNfts, marketNfts]);

  useEffect(() => {
    setNFTs();
  }, [category]);

  useEffect(() => {
    if (displayNFTs) {
      setDisplayNFTs(sortNFTs(displayNFTs));
    }
  }, [sort]);

  return (
    <PageContainer>
      {matches && (
        <Categories
          activeItem={category}
          onChangeCategory={onChangeCategory}
          className="me-2"
        />
      )}
      <NFTListContainer>
        <Div className={classes.filtersCol}>
          <FiltersSection handleFilter={setNFTs} />
        </Div>
        <Div className={classes.listCol}>
          {displayNFTs && <NFTList nfts={displayNFTs} />}
        </Div>
      </NFTListContainer>
    </PageContainer>
  );
};
export default MarketPlacePage;
