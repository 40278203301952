import React, { useState } from 'react';
import { useAccount } from 'wagmi';
import { Box, IconButton, Theme, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import Strings, { ShortAddress } from 'utils/Strings';
import { KeyValueOutputType } from 'models/@types';
import NFTOffers from 'pages/nft/components/nft-offers';
import Circle from 'components/global/circle';
import Div from 'components/global/div';
import GDivider from 'components/global/divider';
import CMPImage from 'components/global/image';
import KeyValueOutput from 'components/global/key-value-output';
import Square from 'components/global/square';
import MagickIcon from 'components/icons/magick-icon';
import LocationIcon from 'components/icons/main/LocationIcon';
import FragmentWrapper from 'components/template/fragment-wrapper';
import OpeanSeaIcon from '../../../../assets/images/opensea.svg';
import EditIcon from '../../../../components/icons/profile/EditIcon';
import TransferIcon from '../../../../components/icons/TransferIcon';
import { DEV_TEST_ADDRESS } from '../../../../constants/Statics';
import { NFT } from '../../../../models/NFT';
import ActionButton from '../action-button';
import InformationContainer, { classes } from './_styles';
import EditNameModal from './components/EditNameModal';
import InformationDescription from './components/information-description';
import InformationOwner from './components/information-owner';
import TransferModal from './components/TransferModal';

interface IProp {
  data?: NFT | null;
  onRefresh?: () => void;
}

const Information: React.FunctionComponent<IProp> = ({ data, onRefresh }) => {
  const { palette } = useTheme();
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [editNameModalOpen, setEditNameModalOpen] = useState<boolean>(false);
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address;
  const theme = useTheme();
  const matches = useMediaQuery<Theme>(theme.breakpoints.down('lg'));

  if (!data) {
    return null;
  }

  const informationBody: KeyValueOutputType[] = [];
  switch (data.category) {
    case 'character':
      informationBody.push(
        { title: 'id', value: { text: `#${data.tokenId.toString()}` ?? '' } },
        {
          title: 'race',
          value: {
            text: data.race?.type ?? '',
            icon: <Circle color={data.race?.color ?? ''} />,
          },
        },
        {
          title: 'Check-in Bonus',
          value: {
            text: data.rarityBonus > 0 ? `+${data.rarityBonus}%` : 'None',
            ...(data.rarityBonus ? {icon: <MagickIcon /> } : {}),
          },
        },
      );
      break;
    case 'land':
      informationBody.push(
        {
          title: 'location',
          value: {
            icon: <LocationIcon color={data.region?.color ?? undefined} />,
            text: data.coordinates ?? '',
          },
        },
        {
          title: 'region',
          value: {
            text: data.region?.text ?? '',
            icon: <Square color={data.region?.color ?? ''} />,
          },
        },
      );
      break;
    default:
    // nothing
  }

  const openSeaURL = (contract: string, tokenId?: number): string => {
    const prefix = 'https://opensea.io/assets/avalanche';
    return `${prefix}/${contract}${tokenId ? '/' + tokenId.toString() : ''}`;
  };

  return (
    <InformationContainer>
      <CMPImage
        asset={data.asset}
        gender={data.attributes?.gender}
        className={classes.image}
        style={{ pointerEvents: 'all' }}
      />
      <Div
        sx={{
          ml: matches ? 0 : 4,
          display: matches ? 'flex' : 'block',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: 450,
          justifyContent: 'space-between',
        }}
      >
        <div>
          {data.name ? (
            <div>
              <Typography
                variant="h6"
                marginTop={matches ? 2 : 0}
                sx={{ '@media screen and (min-width: 600px)': { fontSize: '28px' } }}
              >
                {data.name}
              </Typography>
              <Typography
                variant="h6"
                marginTop={matches ? 2 : 0}
                sx={{ '@media screen and (min-width: 600px)': { fontSize: '18px' } }}
              >
                {data.metadata?.name ?? ''}
              </Typography>
            </div>
          ) : (
            <Typography
              variant="h6"
              marginTop={matches ? 2 : 0}
              sx={{ '@media screen and (min-width: 600px)': { fontSize: '28px' } }}
            >
              {data.metadata?.name ?? ''}
            </Typography>
          )}
        </div>
        <Box>
          {wallet === data.owner && (
            <>
              <IconButton onClick={() => setEditNameModalOpen(true)}>
                <EditIcon color={palette.primary.main} />
              </IconButton>
              <IconButton onClick={() => setTransferModalOpen(true)}>
                <TransferIcon color={palette.primary.main} />
              </IconButton>
            </>
          )}
          <IconButton component={Link} href={`${openSeaURL(data.contract, data.tokenId)}`} target="_blank">
            <CMPImage
              asset={{
                src: OpeanSeaIcon,
                alt: 'opensea',
              }}
              width={28}
              height={28}
            />
          </IconButton>
        </Box>
        <Div className={classes.information} sx={{ my: matches ? 1 : 4, maxWidth: 444, width: '100%' }}>
          {informationBody.map((item, index, arr) => (
            <FragmentWrapper key={item.title}>
              <KeyValueOutput {...item} />
              {arr.length - 1 !== index ? <GDivider sx={{ mx: 4 }} /> : null}
            </FragmentWrapper>
          ))}
        </Div>
        {data && data.rarity && data.category === 'character' && (
          <Div className={classes.information} sx={{ my: matches ? 1 : 4, maxWidth: 444, width: '100%' }}>
            <FragmentWrapper>
              <KeyValueOutput
                rootSx={{ minWidth: '51px' }}
                title={Strings.rank}
                value={{ text: data.rarity.rank.toString(), icon: undefined }}
              />
              <GDivider sx={{ mx: 4 }} />
              <KeyValueOutput
                rootSx={{ minWidth: '51px' }}
                title={Strings.genderRank}
                value={{ text: data.rarity.categoryRank.toString(), icon: undefined }}
              />
            </FragmentWrapper>
          </Div>
        )}
        <InformationOwner contract={data.owner as any} name={ShortAddress(data.owner as any)} />
        {data.sale && (
          <InformationDescription
            description={{
              icon: <MagickIcon />,
              value: data.priceStr,
              price: data.usdtPrice,
            }}
            progressBar={{
              highest: data.sale.startTime.toString(),
              lowest: data.sale.endTime.toString(),
              // progressPercentage: data.durationPercent,
            }}
          />
        )}
        <div className="d-flex align-items-center w-100">
          <ActionButton data={data as unknown as NFT} matches={matches} onRefresh={onRefresh} />
          {data?.offers?.length > 0 && (data.owner as any) === wallet && (
            <NFTOffers
              offers={data.offers}
              tokenId={data.tokenId}
              address={data.contract}
              matches={matches}
              onRefresh={onRefresh}
            />
          )}
        </div>
      </Div>
      <TransferModal open={transferModalOpen} setOpen={setTransferModalOpen} nft={data} />
      <EditNameModal open={editNameModalOpen} setOpen={setEditNameModalOpen} nft={data as unknown as NFT} />
    </InformationContainer>
  );
};

export default Information;
