import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Addresses, } from "../../constants/Addresses";
import Chains from "../../constants/wagmi/chains";
import { GlobalState, SetTokenPricePayload, TokenDetails } from './types';

const newSet = () => ({ price: 0, liquidity: 0 });

const initialState: GlobalState = {
  prices: Object.keys(Addresses[Chains.avalanche.id].Tokens)
    .concat(Object.keys(Addresses[Chains.avalanche.id].LpTokens))
    .reduce((o, a) => {
      o[a] = newSet();
      return o;
    }, {} as { [index: string]: any }),
  secretMode: false,
  nfts: Object.keys(Addresses[Chains.avalanche.id].NFTs).reduce((o, a) => {
    o[a] = {};
    return o;
  }, {} as { [index: string]: any }),
  items: Object.keys(Addresses[Chains.avalanche.id].Items1155).reduce((o, a) => {
    o[a] = {};
    return o;
  }, {} as { [index: string]: any }),
  lastQuery: 0,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAllTokenPrices: (state, action: PayloadAction<TokenDetails>) => {
      state.prices = { ...state.prices, ...action.payload };
    },
    setTokenPrice: (state, action: PayloadAction<SetTokenPricePayload>) => {
      state.prices[action.payload.token] = action.payload.details;
    },
    enableSecretMode: state => {
      state.secretMode = true;
    },
    setGlobalNfts: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.nfts = { ...state.nfts, ...action.payload };
    },
    setGlobalItems: (state, action: PayloadAction<{ [index: string]: any }>) => {
      state.nfts = { ...state.items, ...action.payload };
    },
  },
});

export const { setAllTokenPrices, setTokenPrice, enableSecretMode, setGlobalNfts, setGlobalItems } =
  globalSlice.actions;

export default globalSlice.reducer;
