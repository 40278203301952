import { ConnectKitButton, useModal } from "connectkit";
import React, { useState } from 'react';
import { Box, IconButton, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import Logo from 'components/icons/Logo';
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { Addresses } from "../../../constants/Addresses";
import { useWagmi } from "../../../hooks/wagmi";
import { selectGlobalPrices } from '../../../store/global/hooks';
import { enableSecretMode } from '../../../store/global/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import MagickIcon from '../../icons/magick-icon';
import CMPNavbar from './_styles';
import ChangeTheme from './components/ChangeTheme';
import OpenSeaCollectionsModal from "./components/OpenSeaCollectionsModal";
import { ReactComponent as OpenSeaIcon } from '../../../assets/images/opensea.svg';

const Navbar: React.FunctionComponent = () => {
  const globalPrices = useAppSelector(selectGlobalPrices);
  const dispatch = useAppDispatch();
  const { isConnected } = useAccount();
  const { chain } = useWagmi();
  const { setOpen } = useModal();
  const location = useLocation();
  const [osdOpen, setOSDOpen] = useState<boolean>(false);
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const [secretModeClicks, setSecretModeClicks] = useState(0);

  let secretModeTimeout: NodeJS.Timeout;

  const navigate = useNavigate()
  const nav = () => {
    navigate("/profile");
  }
  const secretMode = () => {
    console.log('Clicked', secretModeClicks);
    clearTimeout(secretModeTimeout);
    const newCount = secretModeClicks + 1;
    if (newCount > 10) {
      return;
    }
    if (newCount == 10) {
      dispatch(enableSecretMode());
      return;
    }
    setSecretModeClicks(newCount);
    secretModeTimeout = setTimeout(() => {
      setSecretModeClicks(0);
    }, 500);
  };

  const addMagick = async () => {
    if (!window.ethereum?.request) {
      return;
    }
    console.log('Adding Magick');
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: Addresses[chain.id].Tokens.MAGICK,
          symbol: 'MAGICK',
          decimals: 18,
          image: `https://github.com/thecosmicuniverse/branding/blob/main/Logo-Gold.png?raw=true`,
        },
      },
    });
  }
  return (
    <>
      <CMPNavbar>
        <div className={
          `d-flex align-items-center w-100 ${!matches ? 'justify-content-between' : 'justify-content-end'}`
        }>
          {matches ? (
            ''
          ) : (
            <Box id="hehe" onClick={secretMode}>
              <Logo />
            </Box>
          )}
          <Stack direction="row" spacing={matches ? 2 : 1} sx={{ alignItems: 'center' }}>
            <IconButton onClick={() => setOSDOpen(true)}>
              <OpenSeaIcon width="36px" height="36px" />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0.5rem 0.25rem 0.25rem 0',
                cursor: 'pointer',
              }}
              onClick={addMagick}
            >
              <MagickIcon />
              <Typography>${globalPrices.MAGICK.price.toFixed(4)}</Typography>
            </Box>
            <ConnectKitButton showBalance={matches} onClick={isConnected ? (location.pathname.endsWith('/profile') ? () => setOpen(true) : () => nav()) : undefined} />
            <ChangeTheme />
          </Stack>
        </div>
        <OpenSeaCollectionsModal open={osdOpen} setOpen={(o) => setOSDOpen(o)} />
      </CMPNavbar>
    </>
  );
};
export default Navbar;
