import React, { FC, Fragment } from 'react';
import { Addresses } from "../../../constants/Addresses";
import Chains from "../../../constants/wagmi/chains";
import GlobalAvaxUpdater from './GlobalAvaxUpdater';
import GlobalItemUpdater from './GlobalItemUpdater';
import GlobalMagicUpdater from './GlobalMagicUpdater';
import GlobalNFTUpdater from './GlobalNFTUpdater';
import GlobalPairUpdater from './GlobalPairUpdater';

const GlobalUpdater: FC = () => {
  return (
    <Fragment>
      {Object.entries(Addresses[Chains.avalanche.id].LpTokens).map(([symbol, address]) => (
        <GlobalPairUpdater key={symbol} symbol={symbol} address={address} />
      ))}
      <GlobalMagicUpdater />
      <GlobalAvaxUpdater />
      <GlobalNFTUpdater />
      <GlobalItemUpdater />
    </Fragment>
  );
};

export default GlobalUpdater;
