import { constants, Signer } from 'ethers';
import store from 'store';
import { setAppLoading } from "store/app/reducer";
import {
  CreateHRC20Contract,
  CreateHRC721Contract,
  GetCosmicElvesMinterContract,
  GetSignedContract,
} from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';
import { Addresses } from '../constants/Addresses';
import Chains from "../constants/wagmi/chains";
import { setMintReady } from '../store/mint/reducer';
import { GetCosmicTicketContract } from '../utils/Contracts';

export const usdcApproval = async (signer: Signer, spender?: string) => {
  const usdc = CreateHRC20Contract(signer, Addresses[Chains.avalanche.id].Tokens.USDC);
  const contract = GetCosmicTicketContract(signer);
  const address = await usdc.signer.getAddress();
  const approved = await usdc.allowance(address, spender || contract.address);
  if (approved.lt(constants.MaxUint256.div(2))) {
    const txApproval = await usdc.approve(spender || contract.address, constants.MaxUint256);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved USDC`,
      severity: 'success',
    });
  }
};

export const lMagicApproval = async (signer: Signer) => {
  const lMagic = CreateHRC20Contract(signer, Addresses[Chains.avalanche.id].Tokens.lMAGIC);
  const contract = GetCosmicElvesMinterContract();
  const address = await lMagic.signer.getAddress();
  const approved = await lMagic.allowance(address, contract.address);
  if (approved.lt(constants.MaxUint256.div(2))) {
    const txApproval = await lMagic.approve(contract.address, constants.MaxUint256);
    await txApproval.wait(1);
    showSnackbar({
      message: `Approved lMAGIC`,
      severity: 'success',
    });
  }
};

export const nftApprovalForAll = async (nftAddress: string, spender: string, signer: Signer) => {
  const nft = CreateHRC721Contract(nftAddress, signer);
  const address = await nft.signer.getAddress();
  const approvedForAll = await nft.isApprovedForAll(address, spender);
  if (!approvedForAll) {
    const tx = await nft.setApprovalForAll(spender, true);
    await tx.wait(1);
    showSnackbar({
      message: `Approved ${await nft.symbol()}`,
      severity: 'success',
    });
  }
};

export const MintETicket = async (count: number, signer: Signer): Promise<boolean> => {
  const contract = GetCosmicTicketContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    await usdcApproval(signer);
    const tx = await contract['mint(uint256)'](count);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Minted ${count} Cosmic Elves Tickets`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const Reveal = async (signer: Signer): Promise<boolean> => {
  const contract = GetCosmicTicketContract(signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.reveal();
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    store.dispatch(setMintReady(false));
    showSnackbar({
      message: `Successfully revealed!`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const Buy = async (signer: Signer): Promise<boolean> => {
  const contract = GetSignedContract(GetCosmicElvesMinterContract(), signer);
  const count = store.getState().mint.elves.toMint;
  const selected = store.getState().mint.elves.ticketsSelected;
  const tickets = Object.values(store.getState().wallet.items721.ElvesTicket || {});
  let ticketIds: number[] = [];
  console.log(selected);
  Object.entries(selected).map(([d, n]) => {
    if (n > 0) {
      ticketIds = ticketIds.concat(
        tickets
          .filter(t => t.discount === d)
          .slice(0, n)
          .map(t => t.tokenId),
      );
    }
  });
  console.log(ticketIds);
  console.log(tickets, store.getState().wallet.nfts);
  try {
    store.dispatch(setAppLoading(true));
    console.log('Getting USDC approval');
    await usdcApproval(signer, contract.address);
    await nftApprovalForAll(Addresses[Chains.avalanche.id].Items721.ElvesTicket, contract.address, signer);
    const tx = await contract.buy(count, ticketIds);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    showSnackbar({
      message: `Successfully Bought ${count} Cosmic El${count > 1 ? 'ves' : 'f'}!`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    // @ts-ignore
    let errorMessage = err?.reason ?? err?.error?.message ?? err?.data?.message ?? err.message;
    if (errorMessage === 'unknown account #0') {
      errorMessage = 'Wallet not connected';
    }
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

export const Mint = async (count: number, signer: Signer): Promise<boolean> => {
  const contract = GetSignedContract(GetCosmicElvesMinterContract(), signer);
  try {
    store.dispatch(setAppLoading(true));
    const tx = await contract.mint(count);
    await tx.wait(1);
    store.dispatch(setAppLoading(false));
    store.dispatch(setMintReady(false));
    showSnackbar({
      message: `Successfully minted!`,
      severity: 'success',
    });
    return true;
  } catch (err) {
    console.log(err);
    // @ts-ignore
    const errorMessage = err?.reason ?? err?.error?.message ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};
