import { ComponentType } from 'react';
import { NFTCategory } from 'models/@types';
import CharacterIcon from 'components/icons/categories/CharacterIcon';
import ItemIcon from 'components/icons/categories/ItemIcon';
import LandIcon from 'components/icons/categories/LandIcon';
import { NFTsState } from '../../../store/wallet/types';

interface Inventory {
  type: NFTCategory;
  amount: number;
  icon: ComponentType<any>;
}

export const userInventories = (nft?: NFTsState, item?: NFTsState): Inventory[] => {

  return [
    {
      amount: nft ? Object.values(nft || {}).reduce((o, a) => {
        o += Object.keys(a).length
        return o;
      }, 0)  : 0,
      type: 'character',
      icon: CharacterIcon,
    },
    // { amount: nft?.companion || 0, type: 'companion', icon: CharacterIcon },
    { amount: nft ? Object.keys(nft?.CosmicIslandLand || {}).length : 0, type: 'land', icon: LandIcon },
    {
      amount: nft ? Object.keys(nft?.FramedWizards || {}).length + Object.keys(nft?.ElvesTicket || {}).length : 0,
      type: 'collectable',
      icon: ItemIcon,
    },
    { amount: item ? Object.values(item || {}).reduce((o, a) => {
        o += Object.keys(a).length
        return o;
      }, 0)  : 0, type: 'item', icon: ItemIcon },
    // { amount: nft?.resource || 0, type: 'resource', icon: ResourceIcon },
    // { amount: nft?.structure || 0, type: 'structure', icon: StructureIcon },
  ];
};

export const filterGlobalNfts = (nfts: NFTsState, address: string): NFTsState => {
  const sNfts: NFTsState = {};
  Object.entries(nfts).map(([name, data]) => {
    sNfts[name] = {};
    Object.entries(data).map(([tokenId, nft]) => {
      if (nft.owner === address) {
        sNfts[name][tokenId] = nft;
      }
    });
  });
  return sNfts;
};
