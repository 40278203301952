import React, { FC, useMemo } from "react";
import { useAccount, useSigner } from 'wagmi';
import { utils } from 'ethers';
import { Box } from '@mui/material';
import { GridSelectionModel, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {
  BatchCancelTraining,
  BatchFinishTraining,
  BatchStake,
  BatchStartTraining,
  BatchUnlockStaking,
  BatchUnstake,
} from 'services/StakeNFT';
import Chains from "../../../../constants/wagmi/chains";
import { useWagmi } from "../../../../hooks/wagmi";
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectStakingStaked } from '../../../../store/staking/hooks';
import { setStakingRefetch } from '../../../../store/staking/reducer';
import { selectMagickWalletBalance, selectWalletNftsWizards } from '../../../../store/wallet/hooks';
import { options } from '../helpers';
import StakingTableToolbarAction from './StakingTableToolbarAction';

interface Props {
  showCheck: boolean;
  setShowCheck: (showCheck: boolean) => void;
  action: string;
  setAction: (action: string) => void;
  rows: { [p: string]: any }[];
  selected: GridSelectionModel;
  setSelected: (selected: GridSelectionModel) => void;
  setShowAlert: (showAlert: boolean, message: string) => void;
}

const StakingTableToolbar: FC<Props> = ({
  setShowCheck,
  action,
  setAction,
  rows,
  showCheck,
  selected,
  setSelected,
  setShowAlert,
}) => {
  const { address } = useWagmi();
  const { data: signer } = useSigner({ chainId: Chains.avalanche.id })
  const magick = useAppSelector(selectMagickWalletBalance);
  const staked = useAppSelector(selectStakingStaked);
  const walletWizards = useAppSelector(selectWalletNftsWizards);
  const dispatch = useAppDispatch();

  const nfts = useMemo(() => {
    return Object.values(walletWizards).map(w => {
      if (!staked[w.id]) {
        return w;
      }
      w.training = staked[w.id].training;
      return w;
    }).filter(w => w.chain?.id === Chains.avalanche.id)
  }, [walletWizards, staked])


  const handleClear = () => {
    setSelected([]);
    setShowCheck(false);
    setAction('');
    setShowAlert(false, '');
  };

  const handleAction = (a: string) => {
    setAction(a);
    setShowCheck(true);
  };

  const handleSubmit = async () => {
    if (!signer) {
      return;
    }
    let success = false;
    switch (action) {
      case 'unlock': {
        const totalCost = utils.parseEther(`${selected.length * 20}.0`);
        if (magick.balance.lt(totalCost)) {
          setShowAlert(
            true,
            `You do not have enough MAGICK to unlock staking. You need ${utils.formatEther(
              totalCost.sub(magick.balance),
            )} more.`,
          );
          break;
        }
        success = await BatchUnlockStaking(selected as number[], signer);
        break;
      }
      case 'stake': {
        success = await BatchStake(selected as number[], signer);
        break;
      }
      case 'unstake': {
        success = await BatchUnstake(selected as number[], signer);
        break;
      }
      case 'start': {
        const totalCost = utils.parseEther(`${selected.length * 90}.0`);
        if (magick.balance.lt(totalCost)) {
          setShowAlert(
            true,
            `You do not have enough MAGICK to start training. You need ${utils.formatEther(
              totalCost.sub(magick.balance),
            )} more.`,
          );
          break;
        }

        success = await BatchStartTraining(
          selected as number[],
          selected.map(n => options(rows.find(r => r.id === n)?.NFTProfessions)[0]),
          signer,
        );
        break;
      }
      case 'finish': {
        success = await BatchFinishTraining(selected as number[], signer);
        break;
      }
      case 'cancel': {
        success = await BatchCancelTraining(selected as number[], signer);
        break;
      }
      default:
        console.log('something is wrong... action:', action);
    }
    if (success) {
      handleClear();
      dispatch(setStakingRefetch(true));
    }
  };

  return (
    <GridToolbarContainer style={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', padding: '10px' }}>
      <Box hidden={showCheck}>
        <StakingTableToolbarAction
          title="Unlock"
          disabled={!nfts.find(w => !w.staking?.unlocked)}
          onClick={() => handleAction('unlock')}
        />
        <StakingTableToolbarAction
          title="Stake"
          disabled={!nfts.find(w => !w.staking?.staked && w.staking?.unlocked && w.totalProfessionPoints !== 40)}
          onClick={() => handleAction('stake')}
        />
        <StakingTableToolbarAction
          title="Unstake"
          disabled={!nfts.find(w => w.staking?.staked && !w.training)}
          onClick={() => handleAction('unstake')}
        />
        <StakingTableToolbarAction
          title="Start Training"
          disabled={!nfts.find(w => !w.training && ![0, 20, 40].includes(w.totalProfessionPoints))}
          onClick={() => handleAction('start')}
        />
        <StakingTableToolbarAction
          title="Finish Training"
          disabled={!nfts.find(w => Date.parse(String(w.training?.completeAt || '99999999999999999')) <= Date.now())}
          onClick={() => handleAction('finish')}
        />
        <StakingTableToolbarAction
          title="Cancel Training"
          disabled={!nfts.find(w => Date.parse(String(w.training?.completeAt || '99999999999999999')) > Date.now())}
          onClick={() => handleAction('cancel')}
        />
      </Box>
      <Box hidden={!showCheck}>
        <StakingTableToolbarAction
          title="Submit"
          disabled={selected.length === 0}
          sx={{ color: 'green', borderColor: 'green' }}
          onClick={handleSubmit}
        />
        <StakingTableToolbarAction title="Cancel" sx={{ color: 'red', borderColor: 'red' }} onClick={handleClear} />
      </Box>
      <Box sx={{ marginLeft: 'auto' }}>
        <GridToolbarExport
          csvOptions={{
            fileName: `cosmic_universe_staking_${address}`,
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
};

export default StakingTableToolbar;
