import CosmicElves from "abis/CosmicElvesABI";
import CosmicWizards from "abis/CosmicWizards";
import AssetCustomizationUpgradeable from "../abis/AssetCustomizationUpgradeable";
import ChestRedeemer from "../abis/ChestRedeemer";
import CosmicBadges from "../abis/CosmicBadges";
import CosmicElvesMinter from "../abis/CosmicElvesMinter";
import CosmicElvesTicket from "../abis/CosmicElvesTicket";
import CosmicIslandLand from "../abis/CosmicIslandLand";
import FramedWizards from "../abis/FramedWizards";
import LMagic from "../abis/LMagic";
import LPToken from "../abis/LPToken";
import Magick from "../abis/Magick";
import MarketUpgradeable from "../abis/MarketUpgradeable";
import ProfessionStakingAvalanche from "../abis/ProfessionStakingAvalanche";
import SimpleMasterInvestor from "../abis/SimpleMasterInvestor";
import StakingRewardsClaimAvalanche from "../abis/StakingRewardsClaimAvalanche";
import XMagic from "../abis/XMagic";
import Chains from "./wagmi/chains";

type ChainTokenContract = 'WAVAX' | 'USDC';
type CosmicTokenContract = 'MAGICK' | 'lMAGIC' | 'xMAGIC';
export type TokenContract = ChainTokenContract | CosmicTokenContract;

export type CosmicCharacterContract = 'Wizards' | 'Elves';
type CosmicNPCContract =
  | 'Market'
  | 'ProfessionStaking'
  | 'AssetCustomization'
  | 'GameStorage'
  | 'ElvesMinter'
  | 'Farm'
  | 'StakingRewardsClaim'
  | 'ElvenAdventures'
  | 'ChestRedeemer';
export type CosmicItem721Contract = 'ElvesTicket' | 'Tools' | 'CosmicIslandLand' | 'FramedWizards';
export type CosmicItem1155Contract =
  | 'Bundles'
  | 'Badges'
  | 'Components'
  | 'Potions'
  | 'RawResources'
  | 'RefinedResources'
  | 'Skins';
type CosmicContract =
  | TokenContract
  | CosmicCharacterContract
  | CosmicNPCContract
  | CosmicItem721Contract
  | CosmicItem1155Contract

export type TokenContractCategory = "Tokens" | "LpTokens"
type ContractCategory = TokenContractCategory | "NFTs" | "Items1155" | "Items721" | "NPCs"

export const ABIs = {
  Tokens: {
    xMAGIC: XMagic,
    MAGICK: Magick,
    lMAGIC: LMagic,
    WAVAX: Magick,
    USDC: Magick,
  },
  LpTokens: {
    MAGIC_USDC: LPToken,
    MAGIC_AVAX: LPToken,
  },
  NFTs: {
    Wizards: CosmicWizards,
    Elves: CosmicElves,
  },
  Items1155: {
    Badges: CosmicBadges,
  },
  Items721: {
    ElvesTicket: CosmicElvesTicket,
    FramedWizards: FramedWizards,
    CosmicIslandLand: CosmicIslandLand,
  },
  NPCs: {
    Market: MarketUpgradeable,
    ProfessionStaking: ProfessionStakingAvalanche,
    AssetCustomization: AssetCustomizationUpgradeable,
    Farm: SimpleMasterInvestor,
    ElvesMinter: CosmicElvesMinter,
    StakingRewardsClaim: StakingRewardsClaimAvalanche,
    ChestRedeemer: ChestRedeemer
  }
} as const;

export const Addresses = {
  [Chains.avalanche.id]: {
    Tokens: {
      xMAGIC: '0x8a990BFE725c490CCaCFC94Ff99E835400dEEA6d',
      MAGICK: '0x9A8E0217cD870783c3f2317985C57Bf570969153',
      lMAGIC: '0x8EebaCaBD19B84AAe85821e9ffa5452b096e769D',
      WAVAX: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    },
    LpTokens: {
      MAGICK_USDC: '0x4514C70a27a2e6035859D477B86C4e21b6209bF6',
      MAGICK_AVAX: '0xb9775B9EFEFAEfA014f159AE43058c954116aa31',
    },
    NFTs: {
      Wizards: '0xBF20c23D25Fca8Aa4e7946496250D67872691Af2',
      Elves: '0x9a337A6F883De95f49e05CD7D801d475a40a9C70',
    },
    Items1155: {
      Badges: '0x03A37A09be3E90bE403e263238c1aCb14a341DEf',
      Components: "0xCB9e8C619CF2C27781dAD8F6e5B27fEBB4f1D89C",
      Potions: "0x54EC1799Cafa5685F0f1A16537b74c02589b7FCe",
      RawResources: '0xF31dC5F3A6D0af979FC3D8ad7A216E2eA49a1Cb1',
      RefinedResources: '0xEacF75f43674a85eae7679E4A67C7FEF004CC7CB',
      Skins: "0x1A356b2B223eB5eb4221a956C67634B7d5c72910",
      Bundles: "0x04AB0F76De2D5ba63082193C66E8ce571A32454E",
    },
    Items721: {
      ElvesTicket: '0x87D0F9ff4B51EEfe9f5f1578bc35e4ddA28bBd1e',
      FramedWizards: '0xf7Faa28f8934D3dcAf571ef3dd0A41BD5604915C',
      CosmicIslandLand: '0x2fa83f2Fa89F275863B9491b1802dFeA5A130024',
      Tools: '0x39Ad4AAfa177f59ed7356fFDBbF36A840075b7A6',
    },
    NPCs: {
      Market: '0x5202e45EbA7F58E350AA58B59dEB64a668654007',
      ProfessionStaking: '0x71e9e186DcFb6fd1BA018DF46d21e7aA10969aD1',
      AssetCustomization: '0x32614E7D3C52Cd66BF2cb8CC17884749E4B070d4',
      GameStorage: '0x3a55FFC97D2183d94147c4D2d3b6991f0C09ABf4',
      Farm: '0xc9d61e23E27A3b75B8fEf4b66a67bFC5737e6262',
      ElvesMinter: '0x8D1dA51e910155d27015F370A4d3718996d47e11',
      StakingRewardsClaim: '0x21C5eB2Fee538D641Ea76B9620B1f80f24eb3791',
    }
  }
} as const;

export const GetCategoryInfoFromToken = (token: string, chainId: number): [string, string, string] => {

  switch (token) {
    case 'CosmicIslandLand':
    case 'FramedWizards':
    case 'Tools':
    case 'ElvesTicket': {
      return [ 'collectables', token, Addresses[chainId].Items721[token] || ''];
    }
    case 'Wizards':
    case 'Elves': {
      return ['character', token, Addresses[chainId].NFTs[token]];
    }
    case 'Components':
    case 'Potions':
    case 'RawResources':
    case 'RefinedResources':
    case 'Skins':
    case 'Badges':
    case 'Bundles': {
      return [ 'items', token, Addresses[chainId].Items1155[token]];
    }
  }
  return ['', '', ''];
};

export const GetTokenNameFromAddress = (address?: string): string => {
  if (!address) {
    return "";
  }
  const map = Object.values(Addresses).reduce((m, a) => {
    const mm = Object.values(a).reduce((i, s) => {
      const im = Object.entries(s).reduce((p, [name, addr]) => {
        p[addr as string] = name;
        return p;
      }, {} as { [addr: string]: string })
      i = {...i, ...im};
      return i;
    }, {});
    m = { ...m, ...mm }
    return m;
  }, {} as { [addr: string]: string });
  return map[address];
};
