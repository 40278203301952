import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Utils from "libs/Utils";
import { INFTProfessions } from "models/NFT";
import ClassicCard from "components/common/classic-card";
import CMPDropdown from "components/common/dropdown";
import Circle from "components/global/circle";
import MagickIcon from "components/icons/magick-icon";
import LocationIcon from "components/icons/main/LocationIcon";
import { BURN_ADDRESS } from "../../../../constants/Statics";
import { NFTState } from "../../../../store/wallet/types";
import ActionButton from "./ActionButton";
interface INFTListViewProps {
  items: NFTState;
}

const NFTListView: React.FunctionComponent<INFTListViewProps> = ({ items }) => {
  const BuildProfessionsArray = (professions: INFTProfessions) => {
    const pList = Object.entries(professions)
      .filter(([, l]) => l > 0)
      .map(([p, l]) => ({
        name: p,
        level: l,
      }));
    if (pList.length < 2) {
      for (let i = pList.length; i < 2; i++) {
        pList.push({
          name: "unknown",
          level: 0,
        });
      }
    }
    return pList;
  };
  return (
    <Grid container spacing={2}>
      {Object.values(items)
        .filter((nft) => nft.owner != BURN_ADDRESS)
        .map((nft) => (
          <Grid key={`${nft.contract}-${nft.tokenId}`} item xs={12} xl={6}>
            <ClassicCard
              // @ts-ignore
              component={Link}
              to={`/gallery/${nft.category}/${nft.contract}/${nft.tokenId}`}
              asset={nft.asset}
              title={nft.name}
              name={nft.customName}
              chain={nft.chain}
              rank={
                nft.category === "character"
                  ? nft.subCategory === "Wizards"
                    ? nft.rarity?.rank
                    : 0
                  : undefined
              }
              body={
                nft.category === "character"
                  ? nft.race
                    ? [
                        {
                          icon: <Circle color={nft.race.color} />,
                          title: nft.race.type,
                        },
                        nft.rarityBonus > 0
                          ? {
                              icon: <MagickIcon />,
                              title:
                                nft.rarityBonus > 0
                                  ? `+${nft.rarityBonus}%`
                                  : "-",
                            }
                          : (undefined as any),
                      ]
                    : undefined
                  : nft.coordinate
                  ? [
                      {
                        icon: (
                          <LocationIcon
                            color={nft.region?.color ?? undefined}
                          />
                        ),
                        title: nft.coordinate,
                      },
                    ]
                  : undefined
              }
              description={
                nft.sale
                  ? {
                      icon: <MagickIcon />,
                      value: nft.priceStr,
                      price: nft.usdtPrice,
                    }
                  : undefined
              }
              // professions={
              //   nft.subCategory === "wizard" && nft.professions
              //     ? BuildProfessionsArray(nft.professions)
              //     : undefined
              // }
              professions={
                nft.professions
                  ? BuildProfessionsArray(nft.professions)
                  : undefined
              }
              time={nft.startDate}
              endIcon={
                nft.metadata.attributes.length > 0 ? (
                  <CMPDropdown
                    iconMode
                    items={nft.metadata.attributes.map((item) => ({
                      key: item.trait_type,
                      text: item.value,
                      extra: Utils.getAttributeRarity(
                        //@ts-ignore
                        nft.attributes.gender,
                        item.trait_type,
                        item.value
                      ),
                    }))}
                    smallItems
                    textItems
                  />
                ) : undefined
              }
              action={<ActionButton nft={nft} />}
            />
          </Grid>
        ))}
    </Grid>
  );
};
export default NFTListView;
