import { constants } from "ethers";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { DEV_MODE, DEV_TEST_ADDRESS } from "../constants/Statics";
import Chains, { networkBaseProviders, networkProviders } from "../constants/wagmi/chains";

export const useWagmi = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data } = useSigner();
  const chainId = chain?.id || Chains.avalanche.id;
  const isCoreChain = chain?.id === Chains.avalanche.id
  const isGameChain = isCoreChain
  const testAddress = DEV_MODE ? (DEV_TEST_ADDRESS as `0x${string}`) : "";
  return {
    address: testAddress || address || constants.AddressZero,
    chain: (isGameChain || isCoreChain) ? chain : Chains.avalanche,
    baseProvider: networkBaseProviders[chainId],
    provider: networkProviders[chainId],
    signer: data!,
    isGameChain,
    isCoreChain,
  }
}
