import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import { DEV_TEST_ADDRESS } from "constants/Statics";
import { NFTCategory } from "models/@types";
import { NFT } from "models/NFT";
import { ItemType } from "models/Filters";
import NFTList from "pages/marketplace/components/nft-list";
import Sort from "pages/marketplace/components/sort";
import ViewSelect from "pages/marketplace/components/view-select";
import Categories from "components/common/categories";
import TabView, { ITabItem } from "components/common/tab-view";
import GDivider from "components/global/divider";
import ForSaleIcon from "components/icons/profile/ForSaleIcon";
import InventoryIcon from "components/icons/profile/InventoryIcon";
import StakingIcon from "../../../../components/icons/side-bar/StakingIcon";
import { useWagmi } from "../../../../hooks/wagmi";
import { filterNFTStateForOwned } from "../../../../services/MarketPlace";
import { selectGlobalNFTs } from "../../../../store/global/hooks";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectMarketNfts } from "../../../../store/market/hooks";
import { selectStakingStaked } from "../../../../store/staking/hooks";
import {
  selectWalletItems1155,
  selectWalletNFTs,
} from "../../../../store/wallet/hooks";
import { NFTsState, NFTState } from "../../../../store/wallet/types";
import { filterGlobalNfts } from "../../functions";
import { setCategory } from "../../../../store/marketplace/reducer";
import NFTListContainer, { classes } from "pages/marketplace/_styles";
import FiltersSection from "pages/marketplace/components/filters";
import Div from "components/global/div";

export type ProfileItemTabs = "Inventory" | "For Sale" | "Staked";

const zipData = (globalState: NFTsState, localState: NFTsState) => {
  const finalState = { ...globalState };
  Object.entries(localState).map(([name, data]) => {
    Object.entries(data).map(([tokenId, nft]) => {
      if (finalState[name]) {
        finalState[name][tokenId] = nft;
      }
    });
  });
  return finalState;
};

const ProfileItems: FC = () => {
  const { address, isCoreChain } = useWagmi();
  const { pathname } = useLocation();
  const wallet = useMemo(() => {
    const s = pathname.split("/");
    if (pathname.endsWith("/profile") || (s.length === 3 && s[2] === address)) {
      return address;
    }
    return s[2];
  }, [address, pathname]);

  const dispatch = useAppDispatch();

  const { category, sort } = useAppSelector((state) => state.marketplace);
  const [activeTab, setActiveTab] = useState<ProfileItemTabs>("Inventory");
  const matches = useMediaQuery<Theme>("(min-width:1250px)", {
    noSsr: true,
  });
  const {
    nfts: walletNfts,
    items721,
    items1155,
  } = useAppSelector((state) => state.wallet);
  const globalNfts = useAppSelector(selectGlobalNFTs);
  const nfts = zipData(filterGlobalNfts(globalNfts, wallet), walletNfts);
  const staked = useAppSelector(selectStakingStaked);
  const forSale = useAppSelector(selectMarketNfts);
  const filters = useAppSelector((state) => state.marketplace);

  const [inventoryNFTs, setInventoryNFTs] = useState<NFTState>();

  const stringToCapitalise = (type: string) => {
    type = type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    return type;
  };

  const sortNFTs = (nftData: NFTState) => {
    const sortMethod = sort.slice(1, sort.length);
    const sortOrder = sort.slice(0, 1);
    const sortedNFTs: NFTState = {};

    if (sortMethod === "rarity" || sortMethod === "tokenId") {
      if (sortMethod === "rarity") {
        Object.entries(nftData)
          .sort(
            ([, a]: [string, NFT], [, b]: [string, NFT]) =>
              ((sortOrder === "+" ? b.rarity?.rank : a.rarity?.rank) || 0) -
              ((sortOrder === "+" ? a.rarity?.rank : b.rarity?.rank) || 0)
          )
          .forEach(([key, _nft]) => {
            sortedNFTs[key] = _nft;
          });
      } else if (sortMethod === "tokenId") {
        Object.entries(nftData)
          .sort(
            ([, a]: [string, NFT], [, b]: [string, NFT]) =>
              (sortOrder === "+" ? a.tokenId : b.tokenId) -
              (sortOrder === "+" ? b.tokenId : a.tokenId)
          )
          .forEach(([key, _nft]) => {
            sortedNFTs[key] = _nft;
          });
      }
      return sortedNFTs;
    }
    return nftData;
  };

  const filterNFTs = (nftData: NFTState) => {
    const newNFTData: NFTState = {};

    if (nftData) {
      Object.entries(nftData).map(([key, item]) => {
        switch (category) {
          case "character":
            if (
              filters.characterId === "" ||
              item.tokenId.toString() === filters.characterId
            ) {
              if (
                filters.races.length === 0 ||
                (item.race && filters.races.includes(item.race.type))
              ) {
                if (
                  filters.blockchains.length === 0 ||
                  (item.chain?.name &&
                    filters.blockchains.includes(item.chain?.name as any))
                ) {
                  if (
                    filters.professions.length === 0 ||
                    (item.professions &&
                      item.professions[filters.professions[0]] > 0)
                  ) {
                    newNFTData[key] = item;
                  }
                }
              }
            }
            break;
          case "land":
            if (
              filters.characterId === "" ||
              item.tokenId.toString() === filters.characterId
            ) {
              if (
                filters.regions.length === 0 ||
                (item.region && filters.regions.includes(item.region.text))
              ) {
                newNFTData[key] = item;
              }
            }
            break;
          default:
            if (
              filters.types.length === 0 ||
              (item.subCategory &&
                (filters.types.includes(
                  stringToCapitalise(item.subCategory + "s") as ItemType
                ) ||
                  filters.types.includes(
                    stringToCapitalise(item.subCategory) as ItemType
                  )))
            ) {
              newNFTData[key] = item;
            }
            break;
        }
      });
    }

    return newNFTData;
  };

  const checkNFTs = (nftData: NFTState) => {
    return filterNFTs(sortNFTs(nftData));
  };

  const setNFTs = () => {
    if (category === "character") {
      setInventoryNFTs(checkNFTs({ ...nfts.Wizards, ...nfts.Elves }));
    } else if (category === "land") {
      setInventoryNFTs(checkNFTs(nfts.CosmicIslandLand));
    } else if (category === "collectable") {
      const collectable = Object.values(items721).reduce((o, n) => {
        if (
          n &&
          Object.keys(n || {}).length > 0 &&
          Object.values(n)[0].subCategory !== "CosmicIslandLand"
        ) {
          o = { ...o, ...n };
        }
        return o;
      }, {});
      setInventoryNFTs(checkNFTs(collectable));
    } else if (category === "item") {
      const items = Object.values(items1155).reduce((o, n) => {
        if (n) {
          o = { ...o, ...n };
        }
        return o;
      }, {});
      setInventoryNFTs(checkNFTs(items));
    }
  };

  useEffect(() => {
    if (inventoryNFTs) {
      setInventoryNFTs(checkNFTs(inventoryNFTs));
    }
  }, [sort]);

  useEffect(() => {
    setNFTs();
  }, [category]);

  const onChangeTab = (tab: ProfileItemTabs) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      dispatch(setCategory(category));
    }
  };

  if (!wallet) {
    return null;
  }

  const onChangeCategory = (cat: NFTCategory) => {
    dispatch(setCategory(cat));
    setNFTs();
  };

  const items: ITabItem<ProfileItemTabs>[] = [
    { key: "Inventory", icon: InventoryIcon },
    { key: "Staked", icon: StakingIcon },
  ];

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-4 pb-4">
      <TabView
        w100
        className="justify-content-center"
        items={isCoreChain ? items : [items[0]]}
        onTabChange={onChangeTab}
        activeTab={activeTab}
        buttonStyle={{ maxWidth: "120px", height: "48px" }}
        style={{ maxHeight: "48px" }}
      />
      <GDivider orientation="horizontal" sx={{ backgroundSize: "100%" }} />
      <NFTListContainer>
        <Div className={classes.filtersCol}>
          <FiltersSection handleFilter={setNFTs} />
        </Div>
        <Div className={classes.listCol}>
          <div
            className={clsx("d-flex my-4 w-100 px-4", {
              "align-items-center justify-content-between": matches,
              "flex-column": !matches,
            })}
          >
            <Categories
              disabledCategories={
                activeTab === "Staked"
                  ? ["land", "item", "collectable"]
                  : undefined
              }
              activeItem={category}
              onChangeCategory={onChangeCategory}
              fullWidth={!matches}
              withoutIcon
            />
            <div
              className={clsx("d-flex align-items-center", {
                "justify-content-between mt-2": !matches,
              })}
            >
              <Sort ban={["price", "create"]} />
              <ViewSelect />
            </div>
          </div>
          {inventoryNFTs && (
            <NFTList
              nfts={
                activeTab === "Inventory"
                  ? inventoryNFTs
                  : activeTab === "Staked"
                  ? staked
                  : category === "character"
                  ? {
                      ...filterNFTStateForOwned(forSale.Wizards, wallet),
                      ...filterNFTStateForOwned(forSale.Elves, wallet),
                    }
                  : category === "land"
                  ? filterNFTStateForOwned(forSale.CosmicIslandLand, wallet)
                  : {
                      ...filterNFTStateForOwned(forSale.ElvesTicket, wallet),
                      ...filterNFTStateForOwned(forSale.FramedWizards, wallet),
                    }
              }
              user={wallet}
              centerGrid
              showFilters={false}
            />
          )}
        </Div>
      </NFTListContainer>
    </div>
  );
};
export default ProfileItems;
