import { Buffer } from 'buffer';
import { BigNumber, Contract, Signer } from 'ethers';
import { Address, Chain } from "wagmi";

import { ABIs, Addresses } from "../constants/Addresses";
import Chains from "../constants/wagmi/chains";
import { NFT, NFTInterface } from '../models/NFT';
import { I1155 } from '../store/global/types';
import { ItemState, NFTState, OwnerState } from '../store/wallet/types';



export const GetCosmicTicketContract = (signer: Signer): Contract =>
  new Contract(Addresses[Chains.avalanche.id].Items721.ElvesTicket, ABIs.Items721.ElvesTicket, signer);

export const TokenURIToNFT = (uri: string, name: string, account?: string, type?: string, balance?: number, contract?: Address, chain?: Chain): NFT => {
  let str = uri;
  let raw: NFTInterface;
  if (typeof uri === 'string') {
    if (!uri.startsWith('{')) {
      const b64 = uri.split(',')[1];
      str = Buffer.from(b64, 'base64').toString();
    }
    raw = JSON.parse(str);
  } else {
    raw = uri;
  }

  if (type) {
    raw.type = type;
  }
  raw.balance = balance;

  return new NFT(raw, name, account, contract, chain);
};

export const TokenURIsToNFTs = (uris: string[], nft: string, account?: string, type?: string, balances?: number[], contract?: Address, chain?: Chain) => {
  return uris
    .map((u, i) => TokenURIToNFT(u, nft, account, type, balances?.[i], contract, chain))
    .reduce((o, a) => {
      o[a.id] = a;
      return o;
    }, {} as NFTState);
};

export const TokenURIsToItems = (name: string, data: I1155): ItemState => {
  return Object.entries(data.uris).reduce((o, [id, uri]) => {
    o[id] = Object.entries(data.owners).reduce((p, [owner, balanceMap]) => {
      if (id in balanceMap) {
        p[owner] = TokenURIToNFT(uri, name, owner, 'ERC1155', balanceMap[Number(id)]);
      }
      return p;
    }, {} as OwnerState);

    return o;
  }, {} as ItemState);
};

export const CleanSymbol = (symbol: string): string => {
  switch (symbol) {
    case 'WAVAX': {
      return 'AVAX';
    }
    default:
      return symbol;
  }
};

export const GetContractName = (address: string, chainId?: number): string => {
  const [t] = Object.entries(Addresses[Chains.avalanche.id].Tokens).find(([, a]) => a === address) || [ ''];
  const [lpt] = Object.entries(Addresses[Chains.avalanche.id].LpTokens).find(([, a]) => a === address) || [ ''];
  return lpt || t;
};

export const GetTokenDiv = (name: string): number => {
  if (['USDC'].includes(name)) {
    return 1e6;
  }
  return 1e18;
};

export const FindWithdrawFeeDetailed = (timeDelta: BigNumber): [number, number, number] => {
  const td = timeDelta.toNumber();
  let [withdrawFee, nextFee, secondsRemaining] = [0, 0, 0];
  if (timeDelta.eq(0)) {
    [withdrawFee, nextFee, secondsRemaining] = [25, 8, 1];
  } else if (timeDelta.gte(1) && timeDelta.lt(3600)) {
    [withdrawFee, nextFee, secondsRemaining] = [8, 4, 3600 - td];
  } else if (timeDelta.gte(3600) && timeDelta.lt(86400)) {
    [withdrawFee, nextFee, secondsRemaining] = [4, 2, 86400 - td];
  } else if (timeDelta.gte(86400) && timeDelta.lt(259200)) {
    [withdrawFee, nextFee, secondsRemaining] = [2, 1, 259200 - td];
  } else if (timeDelta.gte(259200) && timeDelta.lt(432000)) {
    [withdrawFee, nextFee, secondsRemaining] = [1, 0.5, 432000 - td];
  } else if (timeDelta.gte(432000) && timeDelta.lt(1209600)) {
    [withdrawFee, nextFee, secondsRemaining] = [0.5, 0.25, 1209600 - td];
  } else if (timeDelta.gte(1209600) && timeDelta.lt(2419200)) {
    [withdrawFee, nextFee, secondsRemaining] = [0.25, 0.1, 2419200 - td];
  } else if (timeDelta.gte(2419200)) {
    [withdrawFee, nextFee, secondsRemaining] = [0.1, 0, 0];
  }
  return [withdrawFee, nextFee, secondsRemaining];
};
